import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import { data, Form, Link, useLoaderData, useSearchParams } from '@remix-run/react';
import { Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';
import { Alert, AlertDescription } from '~/components/ui/alert';

import LoginBackgroundImage from '~/assets/login/background.jpg';
import { Button } from '~/components/ui/button';
import { Checkbox } from '~/components/ui/checkbox';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { authenticator } from '~/services/auth.server';
import { commitSession, getSession } from '~/services/session.server';

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/',
  });

  const session = await getSession(request.headers.get('cookie'));
  const error = session.get(authenticator.sessionErrorKey);
  return data(
    { error },
    {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    },
  );
}

export async function action({ request }: ActionFunctionArgs) {
  return await authenticator.authenticate('user-pass', request, {
    successRedirect: '/',
    failureRedirect: '/login?error=true',
  });
}

export default function LoginScreen() {
  const [showPassword, setShowPassword] = useState(false);
  const loaderData = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');

  return (
    <div className="grid min-h-screen lg:grid-cols-2">
      <div
        className="hidden bg-cover bg-center lg:block"
        style={{ backgroundImage: `url(${LoginBackgroundImage})` }}
      />
      <div className="flex items-center justify-center p-8">
        <div className="w-full max-w-md space-y-8">
          <div className="space-y-2">
            <h1 className="text-3xl font-bold tracking-tight">
              Welcome to Silvercrest Scaffolding&apos;s CRM System!{' '}
              <span role="img" aria-label="waving hand">
                👋
              </span>
            </h1>
            <p className="text-muted-foreground">Please sign-in below to get started.</p>
          </div>

          {error === 'account-deactivated' && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>
                Your account has been deactivated. Please contact an administrator.
              </AlertDescription>
            </Alert>
          )}

          <Form method="post" className="space-y-6">
            {loaderData?.error && (
              <div className="rounded-md border border-red-200 bg-red-50 p-4 text-red-600">
                {loaderData.error.message}
              </div>
            )}

            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                placeholder="user@silvercrestscaffolding.com"
                required
                type="email"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <div className="relative">
                <Input
                  id="password"
                  name="password"
                  required
                  type={showPassword ? 'text' : 'password'}
                />
                <Button
                  className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                  onClick={() => setShowPassword(!showPassword)}
                  type="button"
                  variant="ghost"
                >
                  {showPassword ? (
                    <EyeOff className="h-4 w-4 text-muted-foreground" />
                  ) : (
                    <Eye className="h-4 w-4 text-muted-foreground" />
                  )}
                  <span className="sr-only">
                    {showPassword ? 'Hide password' : 'Show password'}
                  </span>
                </Button>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Checkbox id="remember" name="remember" />
                <Label htmlFor="remember">Remember me</Label>
              </div>
              <Link className="text-sm font-medium text-primary hover:underline" to="#">
                Forgot Password?
              </Link>
            </div>

            <Button className="w-full bg-sky-500 hover:bg-sky-600" type="submit">
              Login
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
